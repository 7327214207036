import { ReactNode } from 'react';

import masterCard from '../../assets/images/masterCard.png';
import visa from '../../assets/images/visa.png';
import { projectIs } from '../../constants';
import { useTranslate } from '../../hooks/useTranslate';
import Link from '../Link';

import {
  ConnectInfo,
  EmailIcon,
  FooterContainer,
  HomeIcon,
  Image,
  InfoWrapper,
  LinksWrapper,
  PhoneIcon,
} from './styled';

export default function Footer(): JSX.Element {
  const { staticTranslate } = useTranslate();

  function getAddress(): string {
    if (projectIs.omua) {
      return staticTranslate('fullAddressOfUkraineOffice');
    }
    if (projectIs.omis) {
      return 'Emek a shalom 22, Yoqneam ilit, Israel';
    }
    if (projectIs.omkaz) {
      return staticTranslate('RepublicOfKazakhstan');
    }
    return 'Ukraine, Kyiv, 03127, Akademika Glushkova Ave., 1 (VDNG, EC “Ukrainian House”), building V';
  }

  function getConnectionInfo(): ReactNode {
    if (projectIs.omua) {
      return (
        <>
          <LinksWrapper>
            <a href="https://omnicampus.io/" target="_blank" rel="noreferrer">
              {staticTranslate('AboutUsTitle')}
            </a>
            <Link to="/laundry/laundry-offer">
              {staticTranslate('PrivacyPolicyTitle')}
            </Link>
            <Link to="/laundry/laundry-offer">
              {staticTranslate('OfferTitle')}
            </Link>
            <a
              href="https://ua.unmomento.com.ua/tseny/"
              target="_blank"
              rel="noreferrer"
            >
              {staticTranslate('ServicesAndPricesTitle')}
            </a>
          </LinksWrapper>
          <InfoWrapper aria-label="Company email">
            <EmailIcon />
            <ConnectInfo href="mailto:rent@omnicampus.io">
              rent@omnicampus.io
            </ConnectInfo>
          </InfoWrapper>
        </>
      );
    }
    return (
      <InfoWrapper aria-label="Company number">
        <PhoneIcon />
        <ConnectInfo href="tel:8000040010">800-004-0010</ConnectInfo>
      </InfoWrapper>
    );
  }

  return (
    <FooterContainer>
      {!projectIs.omis && getConnectionInfo()}
      <InfoWrapper aria-label="Company address">
        <HomeIcon />
        {getAddress()}
      </InfoWrapper>
      {projectIs.omua && (
        <InfoWrapper className="cards">
          <Image width="50px" height="22px" src={visa} alt="Verified be Visa" />
          <Image
            width="50px"
            height="22px"
            src={masterCard}
            alt="MasterCard SecureCode"
          />
        </InfoWrapper>
      )}
    </FooterContainer>
  );
}
