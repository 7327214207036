import styled from 'styled-components';

import OfferCheckbox from '../../components/OfferCheckbox';
import { infoTextTemplate } from '../../theme/pagesElements';

export const CenteredVerticallyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`;

export const QuestionTitle = styled.h1`
  ${infoTextTemplate};
  font-size: ${({ theme }): string => theme.typography.fontSize.xxxl};
  color: ${({ theme }): string => theme.palette.text.secondary};
  text-align: center;
  padding: 0 ${({ theme }): string => theme.spacing(2)};
`;

export const QuestionIcon = styled.div`
  font-family: ${({ theme }): string => theme.typography.fontFamily.font1};
  font-size: 180px;
  color: ${({ theme }): string => theme.palette.text.secondary};
`;

export const StyledOfferCheckbox = styled(OfferCheckbox)`
  margin-top: ${({ theme }): string => theme.spacing(2)};
  margin-bottom: 0;
`;

export const InfoCDK = styled.p`
  font-family: ${({ theme }): string => theme.typography.fontFamily.font3};
  font-size: ${({ theme }): string => theme.typography.fontSize.xxs};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[400]};
  line-height: 1.3;
  width: 235px;
  text-align: center;
  color: ${({ theme }): string => theme.palette.text.secondary};
  margin-top: ${({ theme }): string => theme.spacing(2)};
`;

export const A = styled.a`
  color: ${({ theme }): string => theme.palette.text.secondary};
  text-decoration: underline;
`;
