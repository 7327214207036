import { useRef, useState } from 'react';
import { QrReader } from 'react-qr-reader';

import Button from '../../components/Button';
import { projectIs } from '../../constants';
import { usePageHeaderContext } from '../../context/PageHeaderContext/PageHeaderContext';
import { useCustomNavigate } from '../../hooks/useCustomNavigate';
import { useHeadTitle } from '../../hooks/useHeadTitle';
import { useTranslate } from '../../hooks/useTranslate';
import { useTypedDispatch } from '../../store';
import { setScannedData } from '../../store/slices/qrSlice';
import { REGEXP_HTTP, REGEXP_UID } from '../constants';
import { getUid } from './utils/qrApiClient';

import { ButtonWrapper, QrScanIcon, QrTitle } from './styled';

export default function QrScan(): JSX.Element {
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useTypedDispatch();

  const savedScannedData = useRef('');

  const { staticTranslate } = useTranslate();
  const { navigateWithUid } = useCustomNavigate();

  function setScannedDataHandel(scannedValue: string): void {
    dispatch(setScannedData(scannedValue));
    navigateWithUid('/order-receive/to-open-cell');
  }

  function processScannedData(qrData: string) {
    savedScannedData.current = qrData;

    const uidRes = qrData.match(REGEXP_UID);

    if (uidRes) {
      setScannedDataHandel(uidRes[0]);
      return;
    }

    const linkRes = qrData.match(REGEXP_HTTP);

    if (linkRes?.[0]) {
      if (projectIs.omkaz) {
        const dividedRes = linkRes[0].split('/');
        const value = dividedRes.at(-1)?.length
          ? dividedRes.at(-1)
          : dividedRes.at(-2);
        setScannedDataHandel(value as string);
        return;
      }

      getUid(linkRes[0])
        .then((uid) => setScannedDataHandel(uid))
        .catch((err) => setErrorMessage(err.message));
    }
  }

  useHeadTitle(staticTranslate('LayoutQrScan'));
  usePageHeaderContext(staticTranslate('OrderReceive'));

  const message = errorMessage
    ? staticTranslate(errorMessage)
    : staticTranslate('QrTitle');

  return (
    <>
      <QrTitle>{message}</QrTitle>
      <QrReader
        constraints={{ facingMode: 'environment' }}
        onResult={(result, error): void => {
          if (result) {
            const data = result.getText();
            if (data !== savedScannedData.current) {
              processScannedData(data);
            }
          }
          if (error?.name && error?.message) {
            setErrorMessage(error.name);
          }
        }}
        containerStyle={{
          width: '100%',
          height: 'calc(100vh - 60px)',
          position: 'fixed',
          top: '60px',
          left: '0',
          overflow: 'hidden',
        }}
        scanDelay={500}
        videoContainerStyle={{
          with: '100%',
          height: '100%',
          padding: '0',
        }}
        videoStyle={{
          objectFit: 'cover',
        }}
      />

      <QrScanIcon role="img" />

      <ButtonWrapper>
        <Button variant="outlined" to="/">
          {staticTranslate('ToMain')}
        </Button>
      </ButtonWrapper>
    </>
  );
}
