import styled from 'styled-components';

import { ReactComponent as QrScanSVG } from '../../assets/images/qrScan.svg';

export const QrTitle = styled.h1`
  font-family: ${({ theme }): string => theme.typography.fontFamily.font3};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[500]};
  font-size: ${({ theme }): string => theme.typography.fontSize.xl};
  color: ${({ theme }): string => theme.palette.text.hightLight};
  background-color: ${({ theme }): string => theme.palette.alphaBlack[60]};
  width: 100%;
  text-align: center;
  padding: ${({ theme }): string => theme.spacing(1.5)};
  z-index: 1;
`;

export const QrScanIcon = styled(QrScanSVG)`
  width: 230px;
  height: calc(100vh - 60px);
  position: absolute;
  z-index: 1;
  filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));

  path {
    fill: ${({ theme }): string => theme.palette.text.hightLight};
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 1;
`;
